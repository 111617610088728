import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TeamRow from './components/TeamRow';
import Table from 'react-bootstrap/Table';
import LeagueNavbar from '../shared/LeagueNavbar';
import contractIcon from '../shared/assets/contract.png';
import './League.css';

const League = () => {
  const { league_key } = useParams();
  console.log("League key:", league_key)
  const [data, setData] = useState(null);
  
  useEffect(() => {
    // Using the environment variable here
    console.log("API BASE URL:", `${process.env.REACT_APP_API_BASE_URL}`)
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/league/${league_key}`;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        setData(data);
      });
  }, [league_key]);

  return (
    <div>
      {data?.teams && <LeagueNavbar teams={data?.teams} league_key={league_key}/>} {/* Render LeagueNavbar if data?.teams exists */}
      <div className='page-content'>
        <h1>{data?.league_information?.name}</h1>
        <hr/>
        <a href={data?.league_information?.rules_url} target="_blank" rel="noopener noreferrer">
          Rulebook
        </a>
        <br/>
        Let me know if something looks wrong, or you have ideas of things to add.
        <hr/>
        <h2>Current salary cap: {data?.league_information.current_cap}</h2>
        <br/>
        <div className="league-summary">
          <Table bordered hover className='table'>
            <thead>
              <tr>
                <th></th>
                <th className="name-th">Team</th>
                <th>Cap Space</th>
                <th>Contract Space</th>
                <th>IR Relief</th>
                <th>IR Contract</th>
                <th>Cap Penalty</th>
              </tr>
            </thead>
            <tbody>
              {data?.teams?.map((team) => (
                <TeamRow 
                key={team._id}
                team={team} 
                icon={team.keepers_confirmed === false ? contractIcon : null} 
                />
              ))}
            </tbody>
          </Table>
        </div>
        {/* Display the timestamp below the league-summary division */}
        {data?.league_information?.last_update && (
          <span style={{ fontSize: '12px' }}>Last Updated: {data.league_information.last_update}</span>
        )}
    </div>
  </div>
  );
};

export default League;
